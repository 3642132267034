import React from "react"
import Layout from "../../components/layout/Layout"
import Markdown from "../../components/markdown/Markdown"
import Section from "../../components/section/Section"
import css from "./We.module.css"
import IconVenus from "../../images/venus.svg"
import MainTitle from "../../components/titles/MainTitle"
import MR from "../../images/mr.jpg"
import wefightIMG from "../../images/wefight.jpg"
import weworkIMG from "../../images/wework.jpg"

export default ({ pageContext: { url, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.we}
      slug={url}
      title="Nosotras"
      theme="we"
    >
      <MainTitle
        title="Nosotras"
        description="Movimiento Manuela Ramos, desde 1978 caminando por la igualdad"
        icon={<IconVenus />}
        theme="we"
      />
      <Section className={css.presentation}>
        <Markdown>{data.we.descripcion.childMarkdownRemark.html}</Markdown>
      </Section>
      <Section className={css.whoarewe}>
        <img src={MR} alt="primer logo" />
        <div>
          <h2>¿Quién es Manuela Ramos?</h2>
          <Markdown>{data.we.quienesSomos.childMarkdownRemark.html}</Markdown>
        </div>
      </Section>
      <Section className={css.visionmision}>
        <div className={css.vision}>
          <h2>Visión</h2>
          <Markdown>{data.we.vision.childMarkdownRemark.html}</Markdown>
        </div>
        <div className={css.mision}>
          <h2>Misión</h2>
          <Markdown>{data.we.mision.childMarkdownRemark.html}</Markdown>
        </div>
      </Section>
      <Section className={css.wefight}>
        <img src={wefightIMG} alt="#Luchamos" />
        <div>
          <p>
            <strong>#LUCHAMOS</strong> para fortalecernos, ganar autonomía e
            iniciar la transformación de nuestras vidas.
          </p>
          <Markdown>{data.we.luchamos.childMarkdownRemark.html}</Markdown>
        </div>
      </Section>
      <Section className={css.wework}>
        <div>
          <p>
            <strong>#TRABAJAMOS</strong> para lograr cambios
          </p>
          <Markdown>{data.we.trabajamos.childMarkdownRemark.html}</Markdown>
        </div>
        <img src={weworkIMG} alt="#Trabajamos" />
      </Section>
      {/*  <Section className={css.memories}>
        <h2 className={css.title}>Memorias</h2>
        <div className="text-column-center">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            porttitor ante vitae sapien gravida, vel lacinia mauris varius.
            Interdum et malesuada fames ac ante ipsum primis in faucibus.
          </p>
        </div>
        <div className={css.cardlist}>
          <div className={css.card}>
            <a
              class="legal-item"
              href="https://drive.google.com/file/d/0B0a6tb1mIo6XWGgwRjNOZnRjMWc/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={css.materialicons}> subject</i>
              <h4 className={css.name}>Locales e inversión</h4>
              <div class="btn">Abrir pdf</div>
            </a>
          </div>
          <div className={css.card}>
            <a
              class="legal-item"
              href="https://drive.google.com/file/d/0B0a6tb1mIo6XWGgwRjNOZnRjMWc/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={css.materialicons}> subject</i>
              <h4 className={css.name}>Locales e inversión</h4>
              <div class="btn">Abrir pdf</div>
            </a>
          </div>
        </div>
      </Section>
      <section className={css.map}>
        <div className="container">
          <h2 className={css.title}>MAPA</h2>
          <div className="text-column-center">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              porttitor ante vitae sapien gravida, vel lacinia mauris varius.
              Interdum et malesuada fames ac ante ipsum primis in faucibus.
            </p>
          </div>
        </div>
      </section>
      <section className={css.programs}>
        <div className="container">
          <h2 className={css.title}>Programas</h2>
          <div className="text-column">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              porttitor ante vitae sapien gravida, vel lacinia mauris varius.
              Interdum et malesuada fames ac ante ipsum primis in faucibus.
            </p>
          </div>
        </div>
      </section>

      <p>3.2.Memorias institucionales (lista de pdfs)</p>

      <p>3.3.Encuéntranos. (MAPA de sedes)</p>

      <p>3.4. Programas </p>

      <p>3.4.1. manuela.org.pe/poder-y-politicas</p>
      <p>Poder y Políticas </p>

      <p>3.4.2. manuela.org.pe/sexualidad-y-autonomia-fisica</p>
      <p>Sexualidad y Autonomía Física</p>

      <p>3.4.3. manuela.org.pe/autonomia-economica</p>
      <p>Autonomía Económica - Unidad CrediMujer </p> */}
    </Layout>
  )
}
